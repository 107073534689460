
import Vue from "vue";

import store from "@/store";

import { menus } from "@/options";

import { mdiDotsVertical } from "@mdi/js";

export interface DataType {
  drawer: boolean;
  version?: string;
}

export default Vue.extend({
  data(): DataType {
    return {
      drawer: true,
      version: process.env.VUE_APP_COMMIT_HASH,
    };
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch("init");

    next();
  },
  computed: {
    icon() {
      return {
        mdiDotsVertical,
      };
    },
    snackbar: {
      get() {
        return store.state.snackbar.show;
      },
      set(value: boolean) {
        store.commit("setSnackbar", {
          ...store.state.snackbar,
          show: value,
        });
      },
    },
    menus() {
      return menus;
    },
    color() {
      return store.state.snackbar.color;
    },
    content() {
      return store.state.snackbar.content;
    },
    isloading() {
      return store.state.loading > 0;
    },
  },
});
